<template>
  <div class="device-monitor-wrapper">
    <div class="device-monitor-banner">
      <img :src="HeaderIcon" alt="header" />
    </div>
    <div class="my-device-wrapper">
      <MyDevice />
    </div>
    <div class="device-main-wrapper" :class="deviceScroll ? 'device-scroll-active' : ''">
      <div class="device-option">
        <!-- <div class="select-main">
          <div class="area-box single-row" @click="()=>{showPopup = true;getLoadGroups()}">
            {{ groupName ? groupName : '全部设备' }}
          </div>
          <van-popup v-model="showPopup" round position="bottom">
            <van-tree-select :items="options" :active-id.sync="activeId" :main-active-index.sync="activeIndex" @click-item='handleConfirmPicker' />
          </van-popup>
        </div> -->
        <div class="select-box">
          <van-dropdown-menu>
            <van-dropdown-item v-model="params.deviceType" :options="option2" @change="handleChangeOption" />
            <van-dropdown-item v-model="params.liveStatus" :options="option3" @change="handleChangeOption" />
            <van-dropdown-item v-model="params.isFollow" :options="option4" @change="handleChangeOption" />
          </van-dropdown-menu>
        </div>
      </div>
      <div class="search-list-wrapper" ref="searchBox">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <DeviceItem v-for="(item, index) in list" :key="'deviceMonitor' + index" :title="item" :item="item" :index="index" :deviceType="deviceType" @handleUpdateDevice="handleUpdateDevice" />
        </van-list>
      </div>
    </div>

    <!--区域-->
    <!-- <Area :showPopup="showPopup" @handleConfirmPicker="handleConfirmPicker" @onCancel="onCancel" /> -->
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { DropdownMenu, DropdownItem, Popup, Picker, Field, Toast } from 'vant'
import { TreeSelect } from 'vant'
import { List, Cell } from 'vant'
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import 'vant/lib/list/style'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import 'vant/lib/field/style'
import 'vant/lib/toast/style'
import 'vant/lib/tree-select/style'
import MyDevice from './MyDevice'
import DeviceItem from './DeviceItem'

import HeaderIcon from '@/assets/img/device/device-monitor-header-bg.jpg'

import {
  getDeviceList,
  deviceDictType,
  deviceTypeEnumList,
  loadGroupsDeviceType,
} from '@/api/deviceMonitor'

Vue.use(TreeSelect)
Vue.use(DropdownMenu).use(DropdownItem)
Vue.use(List).use(Cell)
Vue.use(Popup).use(Picker).use(Field).use(Toast)

export default {
  name: 'DeviceMonitor',
  components: {
    MyDevice,
    DeviceItem,
  },
  data() {
    return {
      HeaderIcon: HeaderIcon,
      showPopup: false,
      groupId: '',
      groupName: '',
      value2: 0,
      value3: 0,
      value4: '',
      activeId: '',
      activeIndex: '',
      options: [],
      option2: [
        {
          text: '全部类型',
          value: '',
        },
      ],
      option3: [
        {
          text: '全部状态',
          value: '',
        },
      ],
      option4: [
        {
          text: '关注',
          value: '',
        },
        {
          text: '我关注的',
          value: 1,
        },
        {
          text: '我不关注',
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      deviceScroll: false,
      selectAreaDataName: '',
      selectAreaDataValue: '',
      params: {
        page: 0,
        size: 10,
        deviceId: '',
        deviceType: '',
        liveStatus: '',
        isFollow: '',
      },
      deviceType: {},
    }
  },

  mounted() {
    // this.getDeviceTypeRequest()
    // this.getDeviceStatusRequest()
    // this.getDeviceListRequest()
    this.getDeviceDictType()
  },

  methods: {
    /**
     * 选择区域
     */
    handleSelectArea() {
      this.showPopup = true
    },
    onCancel() {
      this.showPopup = false
    },
    /**
     * 区域确定选择
     */
    handleConfirmPicker(val) {
      console.log('val')
      console.log(val)
      console.log('val')
      this.params.deviceId = val.id
      this.groupName = val.text
      this.params.page = 0
      this.list = []
      this.getDeviceListRequest()
      this.showPopup = false
    },
    /**
     * 改变选择条件
     */
    handleChangeOption() {
      this.list = []
      this.params.page = 0
      this.getDeviceListRequest()
    },
    /**
     * 更新设备列表
     */
    handleUpdateDevice(val) {
      this.list[val.index].isFollow = val.isFollow
    },
    /**
     * 获取设备列表
     */
    getDeviceListRequest() {
      if (this.params.page === 0) {
        this.list = []
      }
      getDeviceList(this.params).then((res) => {
        const { content, totalElements } = res
        content.forEach((item) => {
          item.type = this.deviceType[item.type]
          item.time = moment(
            new Date().valueOf() - item.lastStartupTime
          ).format('D天H时mm分')
          this.list.push(item)
        })
        this.loading = false
        if (this.list.length >= totalElements) {
          this.finished = true
          this.loading = true
        }
        // else {
        //     setTimeout(() => {
        //         this.params.page = this.params.page + 1
        //         this.getDeviceListRequest()
        //     }, 1000)
        // }
        this.params.page = this.params.page + 1
      })
    },

    getDeviceDictType() {
      /**
       * 获取设备类型
       */
      deviceTypeEnumList().then((res) => {
        this.option2 = [
          {
            text: '全部类型',
            value: '',
          },
        ]
        res.details.forEach((item) => {
          this.option2.push({
            text: item.label,
            value: item.type,
          })
        })
      })
      /**
       * 获取设备状态
       */
      deviceDictType(5).then((res) => {
        this.option3 = [
          {
            text: '全部状态',
            value: '',
          },
        ]
        res.forEach((item) => {
          this.option3.push({
            text: item.label,
            value: item.status,
          })
        })
      })
    },

    getLoadGroups() {
      loadGroupsDeviceType().then((res) => {
        // let result = []
        // res.details.content.map((item) => {
        //   result.push({
        //     text: item.groupName,
        //     id: item.id,
        //   })
        // })
        // this.columns = result

        let data = this.recurrence(
          res.details.content,
          'children',
          'deviceChildren'
        )
        data.unshift({
          label: '设备类型',
          children: [
            {
              deviceName: '全部设备',
              id: '',
            },
          ],
        })
        data.map((item) => {
          item.text = item.label
          item.children.map((items) => {
            items.text = items.deviceName
          })
        })
        this.options = data
      })
    },
    recurrence(data, sameChildren, sameLevelChildren) {
      function recurrenceData(data, sameChildren, sameLevelChildren) {
        let result = data.map((item) => {
          if (item[sameChildren]) {
            recurrenceData(item[sameChildren], sameChildren, sameLevelChildren)
          }
          item[sameChildren] = item[sameChildren].concat(
            item[sameLevelChildren]
          )
          if (
            item[sameChildren].length == 0 &&
            item[sameLevelChildren].length == 0
          ) {
            item[sameChildren].push({ name: '' })
          }
          return item
        })
        return result
      }
      return recurrenceData(data, sameChildren, sameLevelChildren)
    },

    /**
     * 监听列表滚动
     */
    searchScroll() {
      // let top = this.$refs.searchBox.scrollTop
      // if (top === 0) {
      //     this.deviceScroll = false
      // }
      // if (top > 0) {
      //     this.deviceScroll = true
      // }
    },
    onLoad() {
      setTimeout(() => {
        this.getDeviceListRequest()
      }, 500)
    },
  },
  //进入搜索页面时
  beforeRouteEnter(to, from, next) {
    if (from.path == '/') {
      next((vm) => {
        vm.getDeviceDictType()
        vm.params = {
          page: 0,
          size: 10,
          deviceId: '',
          deviceType: '',
          liveStatus: '',
          isFollow: '',
        }
        vm.getDeviceListRequest()
      })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';

.device-monitor-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;

  .device-monitor-banner {
    @include wh(100%, 7rem);
    background-color: #f0f0f0;

    img {
      @include wh(100%, 100%);
    }
  }

  .my-device-wrapper {
    @include wh(100%, 5.5rem);
    background-color: white;
    margin-bottom: 1rem;
  }

  .device-main-wrapper {
    @include wh(100%, 100%);
    box-sizing: border-box;
    padding-top: 50px;
    position: relative;

    &.device-scroll-active {
      position: fixed;
      top: 3rem;
      left: 0;
    }

    .device-option {
      @include wh(100%, 50px);
      display: flex;
      justify-content: space-between;
      background-color: white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      box-sizing: border-box;

      // padding-right: 0.5rem;
      .area-box {
        width: 5rem;
        height: 100%;
        font-size: 15px;
        color: #999;
        text-align: center;
        line-height: 50px;
        position: relative;
        box-sizing: border-box;
        padding-left: 0.1rem;

        &::after {
          position: absolute;
          top: 50%;
          margin-left: 5px;
          margin-top: -5px;
          border: 3px solid;
          border-color: transparent transparent currentColor currentColor;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          opacity: 0.8;
          content: '';
        }
      }
      .select-main {
        width: 5rem;
      }
      .select-box {
        flex: 1;

        /deep/.van-ellipsis {
          color: #999;
        }

        /deep/.van-dropdown-menu__title::after {
          color: #999;
        }
      }

      .follow-box {
        width: 5rem;
        height: 100%;
        font-size: 15px;
        color: #999;
        text-align: center;
        line-height: 50px;
      }
    }

    .search-list-wrapper {
      width: 100%;
      height: 100%;
      background-color: #f1f1f1;
      box-sizing: border-box;
      padding: 0 1rem 3rem 1rem;
      overflow-y: auto;

      /deep/.van-list__finished-text {
        font-size: 0.7rem;
      }
    }
  }
}

.select-address {
  @include wh(100%, 5rem);
  background-color: white;
  box-sizing: border-box;
  padding: 1rem;

  /deep/.van-field {
    .van-field__label {
      width: 6rem;
      @include CC;

      span {
        font-size: 0.7rem;
      }
    }

    .van-field__body {
      input {
        font-size: 0.7rem;
        border: 1px solid #f1f1f1;
        padding: 0.1rem 0.5rem;
        border-radius: 0.2rem;
      }

      input::-webkit-input-placeholder {
        font-size: 0.7rem;
      }

      div {
        font-size: 0.7rem;
      }
    }
  }
}
</style>
