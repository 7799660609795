<template>
  <div class="my-device-box">
    <div class="title">我的设备</div>
    <div class="my-device-list">
      <div class="device-item" v-for="item of DeviceData" :key="item.text" :style="`background: ${item.bg}`">
        <i class="iconfont" :class="item.iconfont" :style="`color: ${item.color}`"></i>
        <p>{{item.text}}</p>
        <h3 v-if="item.num!='stop'">{{NumberArr[item.num]}}</h3>
        <h3 v-else>{{NumberArr['total']-NumberArr['run']}}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceStatus } from '@/api/deviceMonitor'
export default {
  name: 'MyDevice',
  data() {
    return {
      NumberArr: {
        run: '0',
        stop: '0',
        fault: '0',
        repair: '0',
        maintain: '0',
      },
      DeviceData: [
        {
          text: '运行',
          num: 'run',
          bg: 'linear-gradient(180deg,rgba(98,195,46,1) 0%,rgba(145,231,99,1) 100%)',
          color: '#3A9C04',
          iconfont: 'iconcheck-active',
        },
        {
          text: '停机',
          num: 'stop',
          bg: 'linear-gradient(180deg,rgba(250,102,102,1) 0%,rgba(252,155,155,1) 99%)',
          color: '#E20505',
          iconfont: 'iconcha',
        },
        {
          text: '告警',
          num: 'warn',
          bg: 'linear-gradient(180deg,rgba(249,204,105,1) 0%,rgba(250,176,124,1) 100%)',
          color: '#FD7E00',
          iconfont: 'icongantanhao',
        },
        //   {
        //     text: '维修',
        //     num: 'repair',
        //     bg: 'linear-gradient(180deg,rgba(250,137,99,1) 0%,rgba(253,177,152,1) 100%)',
        //     color: '#FF490D',
        //     iconfont: 'iconweixiu2'
        //   },
        //   {
        //     text: '保养',
        //     num: 'maintain',
        //     bg: 'linear-gradient(180deg,rgba(79,147,255,1) 0%,rgba(58,187,253,1) 100%)',
        //     color: '#0265E9',
        //     iconfont: 'icon-baoyang'
        //   },
      ],
    }
  },
  mounted() {
    this.getDeviceStatusRequest()
  },
  methods: {
    /**
     * 我的设备状态统计
     */
    getDeviceStatusRequest() {
      getDeviceStatus().then((res) => {
        this.NumberArr = res
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.my-device-box {
  .title {
    @include wh(100%, 2rem);
    box-sizing: border-box;
    padding-left: 1rem;
    line-height: 2rem;
    font-size: 0.9rem;
    color: black;
  }
  .my-device-list {
    @include wh(100%, 3rem);
    display: flex;
    // justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    .device-item {
      @include wh(2.5rem, 2.5rem);
      position: relative;
      border-radius: 0.5rem;
      text-align: center;
      box-sizing: border-box;
      padding: 0.2rem 0;
      margin-right: 1rem;
      i.iconfont {
        position: absolute;
        font-size: 0.8rem;
        top: -0.5rem;
        right: -0.5rem;
      }
      p {
        color: white;
        font-size: 0.7rem;
      }
      h3 {
        color: white;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
